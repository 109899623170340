<template>
  <div>
    <h3 class="font-21 theme-font-medium py-2">Add API Content Token</h3>
    <p class="color-gray">Token Names have a maximum length of 50 characters and can only contain alphanumeric characters (Aa-Zz, 0-9) and underscore (_).</p>
    <b-form-row>
      <b-col cols="12" sm="10" md="6" lg="5" xl="4">
        <b-form-group>
          <div class="floating-input-field">
            <b-form-input
              id="token-name"
              :class="{
                'is-invalid': !addUpdateApiTokenForm.tokenName && formSubmitted,
              }"
              type="text"
              placeholder=" "
              v-model="addUpdateApiTokenForm.tokenName"
              maxLength="50"
              v-alphaNumericUnderscore
              @input="addUpdateApiTokenForm.tokenName = addUpdateApiTokenForm.tokenName.replace(/[^a-zA-Z0-9_]/gi, '')"
              :disabled="multilingualToggle"
              required
            ></b-form-input>
            <label for="token-name"
              >Token name
              <InheritText v-if="multilingualToggle" />
            </label>
            <b-form-invalid-feedback class="d-block" v-if="!addUpdateApiTokenForm.tokenName && formSubmitted">Token name required.</b-form-invalid-feedback>
            <b-form-invalid-feedback class="d-block" v-if="apiTokenExistError && formSubmitted">Token name already exist.</b-form-invalid-feedback>
          </div>
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="10" md="6" lg="5" xl="4">
        <b-form-group>
          <div class="floating-input-field">
            <b-form-input
              id="token-field"
              :class="{
                'is-invalid': !addUpdateApiTokenForm.tokenField && formSubmitted,
              }"
              type="text"
              placeholder=" "
              v-model="addUpdateApiTokenForm.tokenField"
              :disabled="multilingualToggle"
              required
            ></b-form-input>
            <label for="token-field"
              >Token field
              <InheritText v-if="multilingualToggle" />
            </label>
            <b-form-invalid-feedback class="d-block" v-if="!addUpdateApiTokenForm.tokenField && formSubmitted">Token field required.</b-form-invalid-feedback>
          </div>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-button type="button" v-activeBlur @click="onAddUpdateForm" variant="primary" class="btn-h-44" :disabled="multilingualToggle"
        >{{ isEditFormIndex > -1 ? `Update` : `Add` }}
      </b-button>
      <b-col>
        <b-button type="button" v-activeBlur class="ml-3 btn-h-44" variant="outline-secondary" @click="onResetForm" :disabled="multilingualToggle"
          >Reset</b-button
        >
      </b-col>
    </b-form-row>
    <b-form-row class="mt-4 pb-3">
      <b-col>
        <div class="b-table-sticky-header custom-scroll">
          <table class="table" id="status-list-table">
            <thead>
              <tr>
                <th>Token name</th>
                <th colspan="2">Token field</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="contentTokenList.length">
                <tr v-for="(token, index) in contentTokenList" :key="index">
                  <td>{#{{ token.tokenName }}}</td>
                  <td>{{ token.tokenField }}</td>
                  <td class="text-right pr-0 py-2">
                    <b-dropdown
                      :disabled="multilingualToggle"
                      boundary="window"
                      class="action-dropdown"
                      toggle-class="btn-h-44"
                      no-caret
                      variant="outline-secondary"
                      right
                      no-flip
                    >
                      <template #button-content>
                        Actions
                        <BIconChevronDown class="pt-1" variant="dark" scale="0.65" />
                      </template>
                      <b-dropdown-item title="Edit token" href="javascript:void(0)" @click="editToken(token, index)">Edit</b-dropdown-item>
                      <b-dropdown-item title="Delete token" href="javascript:void(0)" @click="deleteToken(index)">Delete</b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="3" class="text-center">No API Content Tokens available.</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-form-row>
    <ConfirmationModal
      :showModal="showDeleteTokenConfirmModal"
      :title="`CONFIRM`"
      :message="getDeleteErrorMsg"
      :isKey="false"
      @onConfirm="confirmDeleteToken"
      @closeConfirmModal="showDeleteTokenConfirmModal = false"
    />
    <ConfirmationModal
      :showModal="showEditTokenConfirmModal"
      :title="`CONFIRM`"
      :message="'API_CONTENT_TOKEN_EDIT'"
      :isKey="true"
      @onConfirm="confirmEditToken"
      @closeConfirmModal="cancelEditToken"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { BIconChevronDown } from 'bootstrap-vue'
import { CONFIRM_DIALOG_MESSAGES } from '../../../utilities/constants'
export default {
  name: 'ApiContentToken',
  data() {
    return {
      addUpdateApiTokenForm: {
        id: 0,
        tokenName: null,
        tokenField: null,
      },
      isEditFormIndex: -1,
      formSubmitted: false,
      showDeleteTokenConfirmModal: false,
      deleteIndex: -1,
      apiTokenExistError: false,
      editApiTokenData: null,
      showEditTokenConfirmModal: false,
    }
  },
  props: {
    contentTokenList: {
      type: Array,
      default() {
        return []
      },
    },
    culture: {
      type: String,
    },
    defaultCulture: {
      type: String,
    },
  },
  components: {
    BIconChevronDown,
    InheritText: () => import('../InheritText.vue'),
    ConfirmationModal: () => import('../../common/ConfirmationModal.vue'),
  },
  computed: {
    multilingualToggle() {
      return this.isMultilingualSupportEnabled && this.isDefaultCulture
    },
    isDefaultCulture() {
      return this.culture !== this.defaultCulture
    },
    getDeleteErrorMsg() {
      const deleteTokenName = this.deleteIndex > -1 ? this.contentTokenList[this.deleteIndex].tokenName : ''
      return `${CONFIRM_DIALOG_MESSAGES.API_CONTENT_TOKEN_REMOVE} {#${deleteTokenName}}`
    },
    ...mapState({
      selectedTenant: (state) => state.common.selectedTenant,
      isMultilingualSupportEnabled: (state) => state.common.isMultilingualSupportEnabled,
    }),
  },
  methods: {
    onAddUpdateForm() {
      this.formSubmitted = true
      if (this.addUpdateApiTokenForm.tokenName && this.addUpdateApiTokenForm.tokenField) {
        if (this.isEditFormIndex > -1) {
          this.apiTokenExistError = this.contentTokenList
            .filter((fToken) => fToken.tokenName !== this.editApiTokenData.tokenName)
            .some((token) => token.tokenName === this.addUpdateApiTokenForm.tokenName)
          if (this.apiTokenExistError) {
            return
          }
          this.contentTokenList[this.isEditFormIndex] = this.addUpdateApiTokenForm
        } else {
          this.apiTokenExistError = this.contentTokenList.some((token) => token.tokenName === this.addUpdateApiTokenForm.tokenName)
          if (this.apiTokenExistError) {
            return
          }
          this.contentTokenList.push(this.addUpdateApiTokenForm)
        }
        this.onResetForm()
      }
    },
    cancelEditToken() {
      this.showEditTokenConfirmModal = false
      this.onResetForm()
    },
    confirmEditToken() {
      this.showEditTokenConfirmModal = false
    },
    editToken(token, index) {
      this.showEditTokenConfirmModal = true
      this.addUpdateApiTokenForm = { ...token }
      this.editApiTokenData = { ...token }
      this.isEditFormIndex = index
      this.apiTokenExistError = false
    },
    confirmDeleteToken() {
      this.contentTokenList.splice(this.deleteIndex, 1)
      this.showDeleteTokenConfirmModal = false
      this.deleteIndex = -1
      this.onResetForm()
    },
    deleteToken(index) {
      this.showDeleteTokenConfirmModal = true
      this.deleteIndex = index
    },
    onResetForm() {
      this.formSubmitted = false
      this.isEditFormIndex = -1
      this.addUpdateApiTokenForm = {
        id: 0,
        tokenName: null,
        tokenField: null,
      }
      this.editApiTokenData = null
      this.apiTokenExistError = false
    },
  },
  watch: {
    selectedTenant(newValue, oldValue) {
      this.addUpdateApiTokenForm.tokenName = null
      this.addUpdateApiTokenForm.tokenField = null
    },
  },
}
</script>
